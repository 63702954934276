import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { AmmPasswordComponent } from '../amm-password/amm-password.component';
import { FormControl } from '@angular/forms';
import { NgFormValidator } from '../../constants/global.component';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService, RbacService } from '../../services';
import { UseraccountModel } from '../../models';
import { MessageService } from 'primeng/api';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';

@Component({
  selector: 'amm-change-pwd',
  standalone: true,
  imports: [
    CommonModule,
    AmmPasswordComponent,
    IconFieldModule,
    InputIconModule,
  ],
  templateUrl: './amm-change-pwd.component.html',
  styleUrl: './amm-change-pwd.component.scss'
})
export class AmmChangePwdComponent implements OnInit {

  constructor(public ref: DynamicDialogRef<AmmChangePwdComponent>) { }

  private authService = inject(AuthService);
  private dialogConfig = inject(DynamicDialogConfig<AmmChangePwdComponent>);
  private rbacService = inject(RbacService);
  private toastService = inject(MessageService);


  public hasNewPwdError: boolean = false;
  public adminInfo?: UseraccountModel;
  public hasOldPwdError?: boolean;
  public onShowAccountInfo: boolean = true;
  public oldPasswordFrmCnt: FormControl<string | null> = new FormControl('', NgFormValidator.required());
  public newPasswordFrmCnt: FormControl<string | null> = new FormControl('',
    [NgFormValidator.passwordMustHaveUppercase('Let\'s make your password stronger! Try including at least one uppercase letter.'),
    NgFormValidator.passwordMustHaveLowercase('Let\'s make your password stronger! Try including at least one lowercase letter.'),
    NgFormValidator.passwordMustHaveNumber('Let\'s make your password stronger! Try including at least one numeric.'),
    NgFormValidator.minLength(8, 'Let\'s make your password stronger! Try including a minimum of 8 characters.')
    ]);
  public confirmPasswordFrmCnt: FormControl<string | null> = new FormControl('', [NgFormValidator.required()]);


  ngOnInit(): void {
    this.onShowAccountInfo = true;
    this.adminInfo = this.authService.getLogginedUser();


    this.oldPasswordFrmCnt.valueChanges.subscribe(() => {
      if (this.hasOldPwdError) {
        this.hasOldPwdError = false;
      }
    });

    this.newPasswordFrmCnt.valueChanges.subscribe(() => {
      if (this.hasNewPwdError) {
        this.hasNewPwdError = false;
      }
    });

  }

  onCloseBtnClick(): void {
    this.ref.close();
  }

  onChangePwdClick(): void {
    this.onShowAccountInfo = false;
  }

  onConfirmBtnClick(): void {
    this.rbacService.changeUserAccountPassword(this.adminInfo?.loginName || '', this.oldPasswordFrmCnt.value || '', this.newPasswordFrmCnt.value || '')
      .subscribe(res => {
        if (res.dataList && res.dataList[0]) {
          this.ref.close();
          this.authService.rbacLogout();

          this.toastService.add({
            key: 'password-changed-toast',
            summary: 'Password changed',
            detail: 'Please log in again with your new password to AmMall’s admin system.',
            severity: 'success',
            closable: true,
          });

        } else if (res.statusList && res.statusList[0].desc?.includes('not match')) {
          this.hasOldPwdError = true;

        } else if (res.statusList && res.statusList[0].desc?.includes('the same')) {
          this.hasNewPwdError = true;
          this.confirmPasswordFrmCnt.reset();
        }

      })
  }

  getComfirmPasswordError(): boolean {
    if (!this.confirmPasswordFrmCnt.value) {
      return false;
    }
    return (this.newPasswordFrmCnt.value === this.confirmPasswordFrmCnt.value) ? false : true;
  }

  getAllValidInfo(): boolean {
    return (this.oldPasswordFrmCnt.valid &&
      this.newPasswordFrmCnt.valid &&
      this.confirmPasswordFrmCnt.valid &&
      !this.getComfirmPasswordError()
    );
  }

}
