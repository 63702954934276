import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { BadgeModule } from 'primeng/badge';
import { AmmMenuActionsComponent, MenuItem } from '../amm-menu-actions/amm-menu-actions.component';
import { NavigationEnd, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { AmmChangePwdComponent } from '../amm-change-pwd/amm-change-pwd.component';
import { AuthService } from '../../services';
import { ReviewAccountStore } from '../../stores';
import { MessagingStore } from '../../stores/message.store';

@Component({
  selector: 'amm-navigation-side-bar',
  standalone: true,
  imports: [
    CommonModule,
    BadgeModule,
    AmmMenuActionsComponent,
  ],
  templateUrl: './amm-navigation-side-bar.component.html',
  styleUrl: './amm-navigation-side-bar.component.scss'
})
export class AmmNavigationSideBarComponent implements OnInit {

  constructor() { }

  private authService = inject(AuthService);
  private router = inject(Router);
  private dialogService = inject(DialogService);
  public messageStore = inject(MessagingStore);
  public reviewAccountStore = inject(ReviewAccountStore);
  
  public currentTabName: string = '';
  public onShowSellerInfo: boolean = false;
  public onShowFinanceInfo: boolean = false;
  public onShowHeaderActions: boolean = false;
  public MAX_OPEN_ACCOUNT_NUM: number = 99;

  public headerMenuItems: MenuItem[] = [{
    icon: 'password', label: 'Change Password'
  }, {
    icon: 'logout', label: 'Log out'
  }];
  public actionList: AmmSiderbarItem[] = [{
    iconName: 'home', label: SIDEBAR_LABEL.HOME
  }, {
    iconName: 'storefront', label: SIDEBAR_LABEL.SELLERS, actions: [{
      label: SIDEBAR_LABEL.SELLER_ACCOUNTS
    }, {
      label: SIDEBAR_LABEL.REVIEW_ACCOUNTS
    }]
  }, {
    iconName: 'person', label: SIDEBAR_LABEL.BUYERS
  }, {
    iconName: 'orders', label: SIDEBAR_LABEL.ORDERS
  }, {
    iconName: 'chat_bubble', label: SIDEBAR_LABEL.MESSAGES
  }, {
    iconName: 'paid', label: SIDEBAR_LABEL.FINANCIAL, actions: [{
      label: SIDEBAR_LABEL.OVERVIEW
    }, {
      label: SIDEBAR_LABEL.SELLER_PAYOUTS
    }]
  }, {
    iconName: 'bar_chart', label: SIDEBAR_LABEL.STATISTICS
  }];

  ngOnInit(): void {
    this.onShowSellerInfo = false;
    this.onShowFinanceInfo = false;
    this.reviewAccountStore.loadOpenAccounts();

    const snapshot = this.router.routerState.snapshot;
    if (snapshot.url.includes(ROUTER_NAME.MESSAGES)) {
      this._setSidebar(ROUTER_NAME.MESSAGES);

    } else {
      let currentPath = snapshot.root.children[0].children[0].routeConfig?.path || '';
      if (currentPath.length > 0 && currentPath.includes('/')) {
        currentPath = currentPath.split("/")[0];
      } 
      this._setSidebar(currentPath);
    }

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes(ROUTER_NAME.MESSAGES)) {
          this._setSidebar(ROUTER_NAME.MESSAGES);

        } else {
          const currentRoute = (input: string): string => {
            const match = input.match(/mainBody:([^/)\s]+)/);
            return match ? match[1] : '';
          };
  
          this._setSidebar(currentRoute(event.url));
        }
      }
    });
  
  }

  private _setSidebar(path: string): void {
    this.currentTabName = this._getTabName(path);

    if (this.currentTabName === SIDEBAR_LABEL.SELLER_ACCOUNTS || this.currentTabName === SIDEBAR_LABEL.REVIEW_ACCOUNTS) {
      this.onShowSellerInfo = true;
    }
    if (this.currentTabName === SIDEBAR_LABEL.OVERVIEW || this.currentTabName === SIDEBAR_LABEL.SELLER_PAYOUTS) {
      this.onShowFinanceInfo = true;
    }
  }

  onClickHeaderAction(): void {
    this.onShowHeaderActions = !this.onShowHeaderActions;
  }

  private _getTabName(route: string): string {
    switch (route) {
      case ROUTER_NAME.HOME: return SIDEBAR_LABEL.HOME;
      case ROUTER_NAME.SELLER_ACCOUNTS: return SIDEBAR_LABEL.SELLER_ACCOUNTS;
      case ROUTER_NAME.SELLER_DETAILS: return SIDEBAR_LABEL.SELLER_DETAILS;
      case ROUTER_NAME.REVIEW_ACCOUNTS: return SIDEBAR_LABEL.REVIEW_ACCOUNTS;
      case ROUTER_NAME.REVIEW_ACCOUNT_DETAILS: return SIDEBAR_LABEL.REVIEW_ACCOUNT_DETAILS;
      case ROUTER_NAME.BUYERS: return SIDEBAR_LABEL.BUYERS;
      case ROUTER_NAME.ORDERS: return SIDEBAR_LABEL.ORDERS;
      case ROUTER_NAME.MESSAGES: return SIDEBAR_LABEL.MESSAGES;
      case ROUTER_NAME.FINANCIAL: return SIDEBAR_LABEL.OVERVIEW;
      case ROUTER_NAME.SELLER_PAYOUTS: return SIDEBAR_LABEL.SELLER_PAYOUTS;
      case ROUTER_NAME.STATISTICS: return SIDEBAR_LABEL.STATISTICS;
      default: return ''
    }
  }

  private _getRouterName(label: string): string {
    switch (label) {
      case SIDEBAR_LABEL.HOME: return ROUTER_NAME.HOME;
      case SIDEBAR_LABEL.SELLER_ACCOUNTS: return ROUTER_NAME.SELLER_ACCOUNTS;
      case SIDEBAR_LABEL.SELLER_DETAILS: return ROUTER_NAME.SELLER_DETAILS;
      case SIDEBAR_LABEL.REVIEW_ACCOUNTS: return ROUTER_NAME.REVIEW_ACCOUNTS;
      case SIDEBAR_LABEL.REVIEW_ACCOUNT_DETAILS: return ROUTER_NAME.REVIEW_ACCOUNT_DETAILS;
      case SIDEBAR_LABEL.BUYERS: return ROUTER_NAME.BUYERS;
      case SIDEBAR_LABEL.ORDERS: return ROUTER_NAME.ORDERS;
      case SIDEBAR_LABEL.MESSAGES: return ROUTER_NAME.MESSAGES;
      case SIDEBAR_LABEL.OVERVIEW: return ROUTER_NAME.FINANCIAL;
      case SIDEBAR_LABEL.SELLER_PAYOUTS: return ROUTER_NAME.SELLER_PAYOUTS;
      case SIDEBAR_LABEL.STATISTICS: return ROUTER_NAME.STATISTICS;
      default: return '';
    }
  }

  onActionClick(item: AmmSiderbarItem) {
    switch (item.label) {
      case SIDEBAR_LABEL.SELLERS:
        this.onShowSellerInfo = !this.onShowSellerInfo;
        break;
      case SIDEBAR_LABEL.FINANCIAL:
        this.onShowFinanceInfo = !this.onShowFinanceInfo;
        break;
      case SIDEBAR_LABEL.MESSAGES:
        this.router.navigate([ROUTER_NAME.MESSAGES]);
        break;
      case SIDEBAR_LABEL.HOME:
      case SIDEBAR_LABEL.SELLER_ACCOUNTS:
      case SIDEBAR_LABEL.REVIEW_ACCOUNTS:
      case SIDEBAR_LABEL.BUYERS:
      case SIDEBAR_LABEL.ORDERS:
      case SIDEBAR_LABEL.SELLER_PAYOUTS:
      case SIDEBAR_LABEL.STATISTICS:
        this.router.navigate([
          'main', { outlets: { mainBody: [this._getRouterName(item.label)] } }
        ]);
        break;
      case SIDEBAR_LABEL.OVERVIEW:
        this.router.navigate(['main', { outlets: { mainBody: ['financial', 'overview'] } }]);
        break;
    }
  }

  getAvatar(): string {
    return this.getNickName().charAt(0).toUpperCase();
  }

  getNickName(): string {
    if (this.authService.getLogginedUser()) {
      return this.authService.getLogginedUser().nickName || '';
    }
    return '';
  }

  getLoginName(): string {
    if (this.authService.getLogginedUser()) {
      return this.authService.getLogginedUser().loginName || '';
    }
    return '';
  }

  getReviewAccountNum(): string {
    return this.reviewAccountStore.totalOpenAccountNum() <= this.MAX_OPEN_ACCOUNT_NUM ? this.reviewAccountStore.totalOpenAccountNum().toString() : '99+';
  }

  onHeaderActionsClick(action: MenuItem): void {
    if (action.label === 'Log out') {
      this.authService.rbacLogout();

    } else if (action.label === 'Change Password') {
      this.onShowHeaderActions = false;
      const changePwdDialogRef = this.dialogService.open(
        AmmChangePwdComponent,
        {
          showHeader: false,
          closeOnEscape: true,
          width: '640px',
          style: {
            padding: '0px',
            width: '640px',
            border: 'none',
            'border-radius': '16px',
            'min-width': '640px',
            'min-height': '370px',
            'max-height': '580px',
          },
          contentStyle: {
            padding: '0px',
            'border-radius': '16px',
          },
        }
      );

    }
  }

}


export interface AmmSiderbarItem {
  iconName?: string;
  label: string;
  actions?: AmmSiderbarItem[];
}


export const SIDEBAR_LABEL = {
  HOME: 'Home',
  SELLERS: 'Sellers',
  SELLER_ACCOUNTS: 'Seller accounts',
  SELLER_DETAILS: 'Seller accounts',
  REVIEW_ACCOUNTS: 'Review accounts',
  REVIEW_ACCOUNT_DETAILS: 'Review accounts',
  BUYERS: 'Buyers',
  ORDERS: 'Orders',
  MESSAGES: 'Messages',
  FINANCIAL: 'Financial',
  OVERVIEW: 'Overview',
  SELLER_PAYOUTS: 'Seller payouts',
  STATISTICS: 'Statistics'
}

export const ROUTER_NAME = {
  HOME: 'home',
  SELLER_ACCOUNTS: 'seller-accounts',
  SELLER_DETAILS: 'seller-details',
  REVIEW_ACCOUNTS: 'review-accounts',
  REVIEW_ACCOUNT_DETAILS: 'review-account-detail',
  BUYERS: 'buyers',
  ORDERS: 'orders',
  MESSAGES: 'message',
  FINANCIAL: 'financial',
  SELLER_PAYOUTS: 'seller-payouts',
  STATISTICS: 'statistics',
};
